import CapSize from "./CapSize";

function BodyTextLarge({ textAlign, children, ...props }) {
  return (
    <CapSize
      as="p"
      textAlign={textAlign}
      fontSize={[20, 20, 30, 30]}
      lineHeight={["25px", "25px", "40px", "40px"]}
      {...props}
    >
      {children}
    </CapSize>
  );
}

export default BodyTextLarge;
