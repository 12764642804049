import Box from "./Box";

function NarrowCol({ children, ...props }) {
  return (
    <Box maxWidth={1280} mx="auto" {...props}>
      {children}
    </Box>
  );
}

export default NarrowCol;
