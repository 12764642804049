const config = {
  local: {
    API_BASE: "http://localhost:8000",
  },
  staging: {
    API_BASE: "https://staging.trailstone-cms.crewstudio.co",
  },
  production: {
    API_BASE: "https://cms.trailstonegroup.com",
  },
};

export default function getConfig(key) {
  const env = process.env.REACT_APP_ENVIRON || "local";
  return config[env][key];
}
