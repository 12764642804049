import { useEnteredViewport } from "../core/hooks";

function AnimatedHeadingGradientLast({
  text,
  gradientText,
  textAlign,
  baseDelay,
  animate,
}) {
  const { ref, entered } = useEnteredViewport(0.5);
  const words = text.split(" ");
  const staggerAmount = 30;
  const gradientAnimationDelay = `${
    baseDelay
      ? words.length * staggerAmount + baseDelay
      : words.length * staggerAmount
  }ms`;

  const ariaLabel = gradientText ? `${text} ${gradientText}` : text;

  return (
    <h1
      className="SplitTextHeading SplitTextHeading--gradientLast"
      ref={ref}
      style={{ textAlign }}
    >
      <span
        aria-label={ariaLabel}
        role="heading"
        className="SplitText"
        ref={ref}
      >
        {words.map((word, index) => {
          const animationDelay = `${
            baseDelay
              ? index * staggerAmount + baseDelay
              : index * staggerAmount
          }ms`;
          return (
            <span key={animationDelay} className="SplitWordWrapper">
              <span
                aria-hidden="true"
                style={{ animationDelay }}
                className={`SplitWord${entered && animate ? " entered" : ""}`}
              >
                {`${word} `}
              </span>
            </span>
          );
        })}
        {gradientText && (
          <span className="SplitWordWrapper">
            <span
              aria-hidden="true"
              style={{ animationDelay: gradientAnimationDelay }}
              className={`SplitGradient SplitWord${
                entered && animate ? " entered" : ""
              }`}
            >
              {gradientText}
            </span>
          </span>
        )}
      </span>
    </h1>
  );
}

// Usually animation will be triggered by viewport entry, but occasionally
// we might want another event to trigger it. For example, in the page heading
// components the heading is already in the viewport, but we want to wait
// for the menu animation to finish and THEN trigger the heading animation
AnimatedHeadingGradientLast.defaultProps = {
  animate: true,
};

export default AnimatedHeadingGradientLast;
