import { SHARING_IMAGE_FILTER_SPEC } from "../core/constants";
import { getMedia } from "../utils/media";

function Metatags({ metaTitle, metaDescription, imageId }) {
  return (
    <>
      <meta property="og:title" content={metaTitle} />
      <meta name="description" content={metaDescription} />
      <meta property="og:description" content={metaDescription} />
      {typeof imageId !== "undefined" && (
        <meta
          property="og:image"
          content={getMedia(imageId, SHARING_IMAGE_FILTER_SPEC)}
        />
      )}
      <meta name="twitter:site" content="@Trailstonegroup" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content="Trailstone Group" />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={metaDescription} />
    </>
  );
}

export default Metatags;
