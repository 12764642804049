import getConfig from "../core/config";

export function getMedia(imageId, resizeRule) {
  return `${getConfig("API_BASE")}/images/${imageId}/${resizeRule}/`;
}

export function getHeroSizes(id, resize = "fill") {
  const widths = [480, 640, 800, 960, 1280, 1600, 1920, 2400, 2880, 3200];
  const sizes = widths.map((width) => {
    const height = (width / 16) * 9;
    let src;
    if (resize === "fill") {
      src = getMedia(id, `${resize}-${width}x${height}-c0`);
    } else {
      src = getMedia(id, `width-${width}`);
    }
    return `${src} ${width}w`;
  });
  return sizes.join(",");
}
